<template>
  <div class="home">
    <v-row>
      <v-col cols="12" xl="6" sm="6" md="6">
        <v-card class="pa-4" min-height="200" elevation="2">
          <span class="_title"> 会议介绍 </span>
          <div
            v-if="isHide && $store.state.navigation"
            class="introduce desc indent"
            style="margin-top: 20px"
          >
            <p>
              为“践行分子育种，推进种业创新”，2023分子植物育种大会定于12月10-12日在“天府之国”---成都举办。大会将邀请分子植物育种相关领域取得突出成果并具有重大学术影响的专家学者和优秀青年科学家进行学术报告和成果展示。组委会诚挚邀请相关同仁聚焦分子育种，论道成都。
            </p>
            <div class="showBtn">
              <a href="#" @click.stop.prevent="onShow"
                >展开阅读全文<span class="downArrow"></span
              ></a>
            </div>
          </div>
          <div v-else class="introduce desc indent" style="margin-top: 20px">
            <p>
              为“践行分子育种，推进种业创新”，2023分子植物育种大会定于12月10-12日在四川省成都市举办。大会将邀请分子植物育种相关领域取得突出成果并具有重大学术影响的专家学者和优秀青年科学家进行学术报告和成果展示。组委会诚挚邀请相关同仁聚焦分子育种，论道天府之国——四川成都。
            </p>
            <p>
              大会为分子育种感兴趣的同行提供了多种形式的学习和交流机会，包括大会报到日举行的基础报告专场，详细介绍基因编辑、转基因和设计育种的基本原理和方法；29位知名专家组成的专题报告会，全方位交流不同物种和领域的分子育种进展；四位评审专家和10为青年报告者参与的青年科学家论坛，突出体现分子育种青年人才的成就。
            </p>
            <p>
              截至11月30日晚18:00，青年科学家论坛报告摘要已截止提交。从中遴选出了10位优秀候选人，12月11日晚间为青年科学家论坛作报告，并评选优秀报告奖。我们将在大会闭幕式上举行青年科学家论坛优秀报告奖授奖仪式。
            </p>
            <p></p>
            <div v-if="$store.state.navigation" class="hideBtn">
              <a href="#" @click.stop.prevent="onShow"
                >收起<span class="upArrow"></span
              ></a>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6">
        <v-card class="pa-4" min-height="514" elevation="2">
          <span class="_title">
            {{ $store.state.navigation ? '大会导航' : '重要日期' }}
          </span>
          <div v-if="$store.state.navigation" class="home-nav">
            <div
              class="nav-li"
              v-for="(item, i) in nav"
              :key="i"
              @click="jump(item.url)"
            >
              <div class="nav-icon">
                <img :src="item.img" />
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
          <div v-else class="date-content mt-8">
            <ul>
              <li>
                <div class="avatar">
                  <img src="../../assets/img/icon/14.png" alt="" />
                </div>
                <p class="date-content-name">大会报到时间</p>
                <p class="date-content-info">2023年12月10日全天</p>
              </li>
              <li>
                <div class="avatar">
                  <img src="../../assets/img/icon/15.png" alt="" />
                </div>
                <p class="date-content-name">前沿领域基础报告</p>
                <p class="date-content-info">2023年12月10日 18:30-21:30</p>
              </li>
              <li>
                <div class="avatar">
                  <img src="../../assets/img/icon/16.png" alt="" />
                </div>
                <p class="date-content-name">青年科学家论坛</p>
                <p class="date-content-info">2023年12月11日 19:00-22:20</p>
              </li>
              <li>
                <div class="avatar">
                  <img src="../../assets/img/icon/17.png" alt="" />
                </div>
                <p class="date-content-name">大会主题报告</p>
                <p class="date-content-info">2023年12月11-12日 08:30-18:00</p>
              </li>
            </ul>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="4" sm="4" md="4">
        <v-card class="pa-4" min-height="300" elevation="2">
          <span class="_title"> 通知公告 </span>
          <div class="desc mt-4">
            <p>
              <a
                href="https://mp.weixin.qq.com/s?__biz=MzIzODg5ODQ5OA==&mid=2247513893&idx=1&sn=19c63f3788f3ef96d3765bf6797a1956&chksm=e930ab08de47221ea2ca41734d703cf0d2d9165fe7cc8026abcb067a1ffebe589bc85239ef89&token=1028360182&lang=zh_CN#rd"
                target="view_window"
                style="text-decoration:none; color: #666666"
                >2023分子植物育种大会第一轮通知及青年科学家论坛报告征集</a
              >
            </p>
            <p>
              <a
                href="https://mp.weixin.qq.com/s?__biz=MzIzODg5ODQ5OA==&mid=2247514361&idx=1&sn=8540bdab034327108e21accad6cd2a17&chksm=e930a4d4de472dc2a0e123c18ffe56f71fcd531bbad60a4a83b4f816eb16c93fdccdf57cb133&token=328888041&lang=zh_CN#rd"
                target="view_window"
                style="text-decoration:none; color: #666666"
                >2023分子植物育种大会第二轮通知</a
              >
            </p>
            <!-- <p><a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/abstract/202109/会议延期通知.pdf" target="view_window" style='text-decoration:none; color: #666666'>会议延期通知 (2022-02-17)</a></p> -->
            <!--            <p><a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/abstract/202109/%E4%BC%9A%E6%9C%9F%E5%8F%98%E6%9B%B4%E9%80%9A%E7%9F%A5.pdf" target="view_window" style='text-decoration:none; color: #666666'>会期变更通知 (2021-11-26)</a></p>-->
            <!--            <p><a href="https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/abstract/202109/2021%E5%88%86%E5%AD%90%E6%A4%8D%E7%89%A9%E8%82%B2%E7%A7%8D%E5%A4%A7%E4%BC%9A%EF%BC%88%E7%AC%AC%E4%B8%80%E8%BD%AE%EF%BC%89%E4%BC%9A%E8%AE%AE%E9%80%9A%E7%9F%A5%EF%BC%88%E7%9B%96%E7%AB%A0%E7%89%88%EF%BC%89.pdf"  style='text-decoration:none; color: #666666'>第一轮会议通知 (2021-09-10)</a></p>-->
            <!--            <p>网站将于9月10日开放注册 (09-10)</p>-->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="8" sm="8" md="8">
        <v-card class="pa-4 enterprise" elevation="2" :height="300">
          <span class="_title"> 大会组织委员会</span>
          <vue-seamless-scroll
            :data="committeeMember"
            class="seamless-warp"
            :class-option="classOption"
          >
            <table
              cellpadding="10 0"
              style="border-collapse: separate; border-spacing: 0px 10px"
            >
              <tr v-for="(item, index) in committeeMember" :key="index">
                <!--                <td class="tab_index">{{ Number(index) + 1 }}</td>-->
                <td class="tab_unit" v-if="$vuetify.breakpoint.name == 'xs'">
                  {{ item.unitName | ellipsis }}
                </td>
                <td class="tab_unit" v-else>{{ item.unitName }}</td>
                <td class="tab_name">{{ item.name }}</td>
              </tr>
            </table>
          </vue-seamless-scroll>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="8"
        md="8"
        v-show="$vuetify.breakpoint.name == 'xs'"
      >
        <v-card class="pa-4 report" elevation="2">
          <span class="_title"> 大会报告专家 </span>
          <v-carousel
            :height="height"
            interval="4000"
            :cycle="true"
            v-model="model"
          >
            <!-- :height="height" -->
            <v-carousel-item v-for="(item, i) in page" :key="i">
              <ul class="flex-list">
                <li v-for="(value, index) in item" :key="index">
                  <!-- <v-card class="pa-1" :height="carouselHeight"> -->
                  <v-card class="pa-1" :height="cardHeight">
                    <div class="avatar">
                      <img :src="value.imgSrc" alt="" />
                    </div>
                  </v-card>
                  <div class="name">{{ value.name }}</div>
                </li>
              </ul>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4" sm="4" md="4">
        <v-card class="pa-4" min-height="250" elevation="2">
          <span class="_title"> 会议信息 </span>
          <div class="meeting-msg">
            <div class="desc mb0">
              <div class="mt-5">
                <p class="desc-title">会议时间：</p>
                <p>2023年12月10-12日</p>
                <p class="desc-title">主办单位：</p>
                <p v-for="(value, index) in organizer" :key="index">
                  {{ value }}
                </p>
                <p class="desc-title">承办单位：</p>
                <p v-for="(value, index) in undertakingUnit" :key="index">
                  {{ value }}
                </p>
                <p class="desc-title">协办单位：</p>
                <p v-for="(value, index) in coOrganizer" :key="index">
                  {{ value }}
                </p>
                <p class="desc-title">赞助单位：</p>
                <p v-for="(value, index) in approval" :key="index">
                  {{ value }}
                </p>
              </div>
            </div>
          </div>
        </v-card>
        <v-card class="pa-4 mt-5" min-height="250" elevation="2">
          <span class="_title"> 联系方式 </span>
          <div class="desc mb0">
            <div class="mt-5">
              <p class="desc-title">会议注册缴费、大会会务组相关事宜：</p>
              <p>姓名：杨江丽&nbsp;&nbsp;电话：15130252576</p>
              <p>姓名：贾利利&nbsp;&nbsp;电话：15832169932</p>
            </div>
            <div class="mt-1">
              <p class="desc-title">会议合作、商务宣传事宜：</p>
              <p>
                王云腾， 电话：18032998601<br />邮箱：wangyunteng@molbreeding.com
              </p>
              <p>
                王安琪， 电话：13716078499<br />邮箱：wanganqi@molbreeding.com
              </p>
            </div>
            <div class="mt-1">
              <p class="desc-title">酒店住宿相关事宜：</p>
              <p>杨 辰， 电话：13752053275</p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="8"
        md="8"
        v-show="$vuetify.breakpoint.name != 'xs'"
      >
        <v-card class="pa-4 report" elevation="2">
          <span class="_title"> 大会报告专家 </span>
          <v-carousel
            :height="height"
            interval="4000"
            :cycle="true"
            v-model="model"
          >
            <!-- :height="height" -->
            <v-carousel-item v-for="(item, i) in page" :key="i">
              <ul class="flex-list">
                <li v-for="(value, index) in item" :key="index">
                  <!-- <v-card class="pa-1" :height="carouselHeight"> -->
                  <v-card class="pa-1" :height="cardHeight">
                    <div class="avatar">
                      <a href="https://www.molecularbreeding.cn/reporter">
                        <img :src="value.imgSrc" alt="" />
                      </a>
                    </div>
                  </v-card>
                  <div class="name">{{ value.name }}</div>
                </li>
              </ul>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4" sm="4" md="4">
        <v-row>
          <v-col cols="12" xl="12" sm="12" md="12">
            <v-card class="pa-4" min-height="120" elevation="2">
              <span class="_title"> 合作媒体 </span>
              <div class="desc mb0 mt-10 mb-10">
                <v-row class="d-flex media">
                  <v-col
                    class="d-flex justify-center align-center pa-0 mb-4"
                    cols="12"
                    xl="4"
                    sm="4"
                    md="4"
                  >
                    <img
                      class="media-code"
                      src="../../assets/img/code.png"
                      alt=""
                  /></v-col>
                  <v-col class="pa-0" cols="12" xl="8" sm="12" md="12" lg="8">
                    <div class="media-content">
                      <div class="media-title">
                        石家庄博瑞迪生物技术有限公司
                      </div>

                      <p>
                        公司地址：河北省石家庄市高新技术产业开发区太行南大街769号京石协作创新示范园A12栋5层
                      </p>
                      <p>公司邮箱：info@molbreding.com</p>
                      <p>联系电话：400-008-9521</p>
                      <p>公司网址：www.molbreding.com</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <!--        <v-card class="pa-4 enterprise" elevation="2">-->
        <!--          <span class="_title"> 赞助单位 </span>-->
        <!--          <ul>-->
        <!--            <li v-for="(value, index) in enterprises" :key="index">-->
        <!--              <div class="avatar">-->
        <!--                <a :href="value.href">-->
        <!--                  <img :src="value.url" />-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </v-card>-->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  committeeMemberList,
  enterpriseList,
  reporterList,
  meeting_info,
} from '../../data/d2023';
import vueSeamlessScroll from 'vue-seamless-scroll';

export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      isHide: true,
      model: 0,
      nav: [
        {
          img: require('@/assets/img/nav/1.png'),
          name: '报名缴费',
          url: '/attend',
        },
        {
          img: require('@/assets/img/nav/2.png'),
          name: '大会报告人',
          url: '/reporter',
        },
        {
          img: require('@/assets/img/nav/3.png'),
          name: '酒店预订',
          url: '/hotelReserve',
        },
        {
          img: require('@/assets/img/nav/4.png'),
          name: '会议日程',
          url: '/schedule',
        },
        {
          img: require('@/assets/img/nav/5.png'),
          name: '组织机构',
          url: '/organization',
        },
        {
          img: require('@/assets/img/nav/6.png'),
          name: '商务合作',
          url: '/exhibition',
        },
        {
          img: require('@/assets/img/nav/7.png'),
          name: '下载中心',
          url: '/download',
        },
        {
          img: require('@/assets/img/nav/8.png'),
          name: '摘要墙报',
          url: '/abstract',
        },
        {
          img: require('@/assets/img/nav/9.png'),
          name: '个人中心',
          url: '/user',
        },
      ],
      classOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 43, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      page: [],
      // 组委会url
      committee: [
        {
          url:
            'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/organizer/中国农业大学.jpeg',
        },
        {
          url:
            'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/organizer/东北农业大学.jpeg',
        },
        {
          url:
            'https://breeding-club.s3.cn-northwest-1.amazonaws.com.cn/2021/organizer/bjdx.jpeg',
        },
      ],
      //大会组织委员会
      committeeMember: [],
      // 展览企业url
      enterprises: [],
      // 大会报告人
      list: [],
      // 主办单位
      organizer: [],
      //承办单位
      undertakingUnit: [],
      //协办单位
      coOrganizer: [],
      // 大会组织委员会
      organizationalCommitteeOfConference: [],
    };
  },
  filters: {
    // 名字长度截取
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 12) {
        return value.slice(0, 12) + '...';
      }
      return value;
    },
  },
  created() {
    this.enterprises = enterpriseList;
    this.list = reporterList;
    this.committeeMember = committeeMemberList;
    this.organizer = meeting_info.organizer;
    this.undertakingUnit = meeting_info.undertakingUnit;
    this.coOrganizer = meeting_info.coOrganizer;
    this.approval = meeting_info.approval;
    (this.organizationalCommitteeOfConference =
      meeting_info.organizationalCommitteeOfConference),
      this.getList();
  },
  // computed(){

  //       },
  methods: {
    onShow() {
      this.isHide = !this.isHide;
    },

    getList() {
      this.page = this.spArr(this.list, 10);
    },
    jump(url) {
      url === '/attend' && this.$store.state.user.name
        ? this.$router.push('/user?num=1')
        : this.$router.push(url);
    },
    spArr(arr, num) {
      let newArr = [];
      for (let i = 0; i < arr.length; ) {
        newArr.push(arr.slice(i, (i += num)));
      }
      return newArr;
    },
  },
  mounted() {},
  computed: {
    /* eslint-disable */

    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 100;
        case 'sm':
          return 100;
        case 'md':
          return 100;
        case 'lg':
          return 120;
        case 'xl':
          return 120;
      }
    },
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 100;
        case 'sm':
          return 150;
        case 'md':
          return 100;
        case 'lg':
          return 200;
        case 'xl':
          return 200;
      }
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 300;
        case 'sm':
          return 400;
        case 'md':
          return 300;
        case 'lg':
          return 550;
        case 'xl':
          return 550;
      }
    },

    /* eslint-disable */
  },
};
</script>

<style lang="less" scoped>
.seamless-warp {
  overflow: hidden;
  margin-top: 10px;
  height: 90%;

  table {
    width: 100%;
    background-color: #ffffff;
  }
  .tab_unit {
    width: 70%;
  }
  td {
    text-align: center;
    padding: 8px 0;
    color: #333333;
  }
  .tab_index {
    width: 10%;
  }
  table tr {
    background-color: #f7f7f7;
  }
  table tr td {
    background-color: transparent;
  }
  table tr:nth-child(even) {
    background-color: #f7f7f7;
  }
}

.meeting-msg {
  height: 280px;
  overflow-y: auto;
}
.home {
  padding-bottom: 100px;
  .introduce {
    position: relative;
    .showBtn {
      width: 100%;
      position: absolute;
      background: linear-gradient(rgba(255, 255, 255, 0.5), white);
      text-decoration: none;
      height: 60px;
      line-height: 100px;
      bottom: 0px;
      text-align: center;
      a {
        text-decoration: none;
      }
    }
    .hideBtn {
      bottom: 0px;
      text-align: center;
      a {
        text-decoration: none;
      }
    }
    .downArrow {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-right: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(45deg);
      margin-bottom: 3px;
      margin-left: 5px;
    }
    .upArrow {
      margin-left: 5px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-left: 1px solid;
      border-top: 1px solid;
      transform: rotate(45deg);
    }
  }

  .home-nav {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    .nav-li {
      width: 100px;
      height: 105px;
      background: url('../../assets/img/nav/bg.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      margin-top: 20px;
      .nav-icon {
        width: 26px;
        height: 27px;
        margin: 0 auto;
        margin-top: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        margin-top: 10px;
        color: #fff;
        font-size: 13px;
      }
    }
  }
  .media {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .media-title {
    //background: #0051b7;
    background: #16a086;
    width: 240px;
    height: 18px;
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
  }
  .media-code {
    width: 100px;
    height: 100px;
  }
  .media-content {
    width: 240px;
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto;
  }
  ._title {
    height: 40px;
    //color: #1867c0;
    color: #333333;
    font-size: 20px;
    padding: 10px 0;
    background: #fff;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 3px;
      border-radius: 20px;
      //background: #1867c0;
      background: #16a086;
      position: absolute;
      left: 0;
      z-index: 2;
      bottom: 0;
    }
  }
  .date-content {
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-left: 0;
      li {
        width: 45%;

        background: linear-gradient(0deg, #16a086, #5dc055);
        border-radius: 10px;
        margin-top: 20px;
        .avatar {
          width: 92px;
          height: 72px;
          margin: 0 auto;
          margin-top: 32px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .date-content-name {
          font-size: 15px;
          color: #fff;
          left: 45px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 0;
        }
        .date-content-info {
          color: #fff;
          font-size: 14px;
          opacity: 0.6;
          text-align: center;
        }
      }
    }
  }
  .desc {
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
    &.mb0 {
      p {
        margin-bottom: 0;
      }
    }
    .desc-title {
      font-weight: bold;
      font-size: 15px;
    }
  }
  .indent {
    text-indent: 2em;
  }
  .flex-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    justify-content: flex-start;
    padding-left: 0 !important;
    margin: 10px auto;
    li {
      list-style: none;
      width: 18%;
      margin: 1%;
    }
  }
  .report {
    .name {
      font-size: 14px;
      text-align: center;
      margin-top: 1px !important;
    }
    .v-card {
    }
    .avatar {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .enterprise {
    ul {
      margin-top: 10px;
      display: flex;
      padding-left: 0;
      flex-wrap: wrap;
      li {
        list-style: none;
        width: 17.5%;
        height: auto;
        margin-top: 10px;
        .avatar {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
